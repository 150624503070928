import React, { useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { loadUserAgreement } from '../../thunks/legalDocumentsThunks';
import './UserAgreement.scss';

const UserAgreement: React.FC = () => {
  const dispatch = useDispatch();
  const userAgreement = useSelector((state: RootState) => state.legalDocuments.userAgreement);

  useEffect(() => { // @ts-ignore
    dispatch(loadUserAgreement());
  }, [dispatch]);

  const error = useSelector((state: RootState) => state.legalDocuments.error);

  return <div className="content">
    <div className="content__inner">
      {
        error
          ? <div>Что-то пошло не так(. Попробуйте позже.</div>
          : userAgreement
            ? <ReactMarkdown>{ userAgreement }</ReactMarkdown>
            : <div>Пользовательское соглашение загружается...</div>
      }
    </div>
  </div>
}

export default UserAgreement;
