const ORIGIN_URL = process.env.REACT_APP_ORIGIN_PROTOCOL
  + '://'
  + process.env.REACT_APP_ORIGIN_HOST
  + (process.env.REACT_APP_ORIGIN_PORT === '80' || !process.env.REACT_APP_ORIGIN_PORT
    ? ''
    : ':' + process.env.REACT_APP_ORIGIN_PORT
  );

export const uploadFile = (file: File): Promise<Response> => {
  const formData: FormData = new FormData();

  formData.append('image', file);
  formData.append('model', 'u2netp');

  const requestOptions: RequestInit = {
    method: 'POST',
    body: formData,
  };

  return fetch(ORIGIN_URL + '/process', requestOptions);
};

export const checkState = (id: string): Promise<Response> => {
  return fetch(`${ORIGIN_URL}/state/${id}`);
}

export const getProcessedImage = (imageURL: string): Promise<Response> =>  {
  return fetch(imageURL);
}

export const fetchUserAgreement = (): Promise<Response> => {
  return fetch(`${ORIGIN_URL}/ua`);
};

export const fetchPrivacyPolicy = (): Promise<Response> => {
  return fetch(`${ORIGIN_URL}/pp`);
};
