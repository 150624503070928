import { createAsyncThunk } from '@reduxjs/toolkit';
import { uploadFile } from '../api';
import { createTimeout, getIntFromEnv, RejectedByTimeout } from '../utils';

export type FileUploadingResponse = {
  client_id: string;
  message: string;
  state: string;
} | { error: string; }

const fileUploadingTimeoutMs = getIntFromEnv('REACT_APP_UPLOADING_TIMEOUT_MS', 8000);

export const uploadFileThunk = createAsyncThunk(
  'fileFlow/uploadFile',
  async (file: File, { rejectWithValue }) => {
    try {
      const response: Response | RejectedByTimeout = await Promise.race([
        uploadFile(file),
        createTimeout(fileUploadingTimeoutMs, 'File uploading'),
      ]);

      if (!response.ok) {
        throw new Error((response as RejectedByTimeout).message
          ? (response as RejectedByTimeout).message
          : 'Network response was not ok.',
        );
      }

      const data: FileUploadingResponse = await response.json();

      if ('error' in data) {
        throw new Error(data.error || 'Unknown error occurred.');
      }

      return data;
    } catch (error) {
      return rejectWithValue((error as Error)?.message ? (error as Error).message : error);
    }
  }
);
