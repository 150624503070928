import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface LegalDocumentsState {
  userAgreement: string | null;
  privacyPolicy: string | null;
  loading: boolean;
  error: boolean;
}

const initialState: LegalDocumentsState = {
  userAgreement: null,
  privacyPolicy: null,
  loading: false,
  error: false,
};

const legalDocumentsSlice = createSlice({
  name: 'legalDocuments',
  initialState,
  reducers: {
    requestLegalDocuments(state) {
      state.loading = true;
    },
    setUserAgreement(state, action: PayloadAction<string>) {
      state.userAgreement = action.payload;
      state.loading = false;
    },
    setPrivacyPolicy(state, action: PayloadAction<string>) {
      state.privacyPolicy = action.payload;
      state.loading = false;
    },
    legalDocumentsError(state) {
      state.loading = false;
      state.error = true;
    },
  },
});

export const { requestLegalDocuments, setUserAgreement, setPrivacyPolicy, legalDocumentsError } = legalDocumentsSlice.actions;

export const legalDocumentsReducer = legalDocumentsSlice.reducer;
