import React, { useEffect, useState } from 'react';
import Button from '../Button/Button';
import './Tabs.scss';

export type TabProps = {
  id?: string;
  title: string;
  content: React.ReactNode;
  size?: 'xs' | 's' | 'm' | 'l' | 'xl';
};

export type TabsProps = {
  tabs: TabProps[];
  activeTabType?: 'primary' | 'secondary' | 'accent' | 'info' | 'light' | 'dark';
  notActiveTabType?: 'primary' | 'secondary' | 'accent' | 'info' | 'light' | 'dark';
  initialActiveTabIndex?: number;
};

const Tabs: React.FC<TabsProps> = ({
  tabs,
  activeTabType = 'light',
  notActiveTabType = 'secondary',
  initialActiveTabIndex = 0,
}) => {
  const [activeTab, setActiveTab] = useState(initialActiveTabIndex);

  useEffect(() => {
    setActiveTab(initialActiveTabIndex);
  }, [ initialActiveTabIndex ]);

  return (
    <div className="tabs__container">
      <div className="tabs__titles">
        {tabs.map((tab, index) => (
          <Button
            key={tab.id ? tab.id : index}
            type={activeTab === index ? activeTabType : notActiveTabType}
            size={tab.size}
            roundedCorners="top"
            onClick={() => setActiveTab(index)}
            disabled={activeTab === index}
          >
            {tab.title}
          </Button>
        ))}
      </div>
      <div className="tab-content">
        {tabs[activeTab].content}
      </div>
    </div>
  );
};

export default Tabs;
