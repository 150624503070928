import React, { useCallback, useRef, useState } from 'react';
import './Dropzone.scss';

type DropzoneProps = {
  children: React.ReactNode;
  inputId?: string;
  onFileSelect?: (file: File) => void;
  onFilesSelect?: (files: FileList) => void;
  multiple?: boolean;
  accept?: string;
};

const Dropzone: React.FC<DropzoneProps> = ({
  children,
  inputId,
  onFileSelect = () => {},
  onFilesSelect = () => {},
  multiple = false,
  accept = 'image/*',
}) => {
  const [dragActive, setDragActive] = useState(false);
  const fileInputRef: React.RefObject<HTMLInputElement> = useRef<HTMLInputElement>(null);

  const handleDrag = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(event.type === "dragenter" || event.type === "dragover");
  }, []);

  const handleFiles = useCallback((files: FileList | null) => {
    if (multiple && files && files.length) {
      onFilesSelect(files);
    } else if (!multiple && files && files[0]) {
      onFileSelect(files[0]);
    }
  }, [ onFileSelect, onFilesSelect, multiple ]);

  const handleDrop = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false);

    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      handleFiles(event.dataTransfer.files);
      event.dataTransfer.clearData();

      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  }, [handleFiles]);

  const openFileDialog = () => {
    fileInputRef.current?.click();
  };

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    if (event.target.files) {
      handleFiles(event.target.files);
    }

    event.target.value = '';
  }, [handleFiles]);

  return (
    <div
      className={`dropzone ${dragActive ? 'active' : ''}`}
      onDragEnter={handleDrag}
      onDragLeave={handleDrag}
      onDragOver={handleDrag}
      onDrop={handleDrop}
    >
      <input
        ref={fileInputRef}
        type="file"
        id={inputId}
        multiple={multiple}
        accept={accept}
        onChange={handleChange}
        style={{ display: 'none' }}
      />
      <label htmlFor={inputId} onClick={openFileDialog}>
        {children}
      </label>
    </div>
  );
};

export default Dropzone;
