import React from 'react';
import { ReactComponent as LoadingSpinnerSVG } from './LoadingSpinner.svg';
import './LoadingSpinner.scss';

const sizeMap = {
  xs: '14px',
  s: '18px',
  m: '24px',
  l: '24px',
  xl: '30px'
};

type LoadingSpinnerProps = {
  size?: 'xs' | 's' | 'm' | 'l' | 'xl';
  type?: 'primary' | 'secondary' | 'accent' | 'info' | 'light' | 'dark';
};

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ size = 'm', type = 'primary' }) => {
  const spinnerSize = sizeMap[size];

  return (
    <div className={`loading-spinner loading-spinner_${type}`} style={{ width: `calc(${spinnerSize} * 2)`, height: spinnerSize }}>
      <LoadingSpinnerSVG />
    </div>
  );
};

export default LoadingSpinner;
