import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store';
import Home from './pages/Home/Home';
import About from './pages/About/About';
import UserAgreement from './pages/UserAgreement/UserAgreement';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import './App.scss';
import './fonts/agbalumo/agbalumo.css';

function App() {
  return (
    <Router>
      <Provider store={store}>
        <header>
          <div className="inner">
            <a className="logo-1stphoto" href="/">
              <span className="logo-1stphoto__1st">1st</span>
              <span className="logo-1stphoto__photo">photo</span>
            </a>

            <nav>
              {/*<ul>*/}
              {/*  <li><Link to="/">Home</Link></li>*/}
              {/*  <li><Link to="/about">About</Link></li>*/}
              {/*</ul>*/}

              {/*<ul>*/}
              {/*  <li><Link to="/">Remove background</Link></li>*/}
              {/*  <li><Link to="/about">Login</Link></li>*/}
              {/*</ul>*/}
            </nav>
          </div>
        </header>

        <div className="main-content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/user-agreement" element={<UserAgreement />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          </Routes>
        </div>

        <footer>
          <div className="inner">
            <div className="footer__content f-content">
              <div className="f-content__row-1">
                <span className="copy">&copy; 2023 1stphoto</span>
                <Link to="/user-agreement">Пользовательское соглашение</Link>
                <Link to="/privacy-policy">Политика конфиденциальности</Link>
              </div>
              <div className="f-content__row-2">
                <span>По всем вопросам пишите на&nbsp;help@1stphoto.io</span>
              </div>
            </div>
          </div>
        </footer>
      </Provider>
    </Router>
  );
}

export default App;
