import React, { useEffect, useState } from 'react';
import Tabs, { TabProps } from '../Tabs/Tabs';
import './ImageComparer.scss';

type ImageComparerProps = {
  originalImageUrl: string;
  processedImageUrl: string;
};

const ImageComparer: React.FC<ImageComparerProps> = ({ originalImageUrl, processedImageUrl }) => {
  const [tabs, setTabs] = useState<TabProps[]>([
    {
      id: 'original-img',
      title: 'Исходное',
      content: <div className="image image_original"><img src={originalImageUrl} alt="Original" /></div>,
      type: 'secondary',
      size: 'm'
    } as TabProps,
    {
      id: 'processed-img',
      title: 'Обработанное',
      content: '',
      type: 'primary',
      size: 'm'
    } as TabProps,
  ]);

  useEffect(() => {
    setTabs([
      tabs[0],
      {
        ...tabs[1],
        content: processedImageUrl
          ? <div className="image image_processed"><img src={processedImageUrl} alt="Обработанное изображение" /></div>
          : <div className="image image_processed"></div>,
      }
    ]);
  }, [ tabs, processedImageUrl ]);

  return (
    <div className="image-comparer">
      <Tabs tabs={tabs} initialActiveTabIndex={1} />
    </div>
  );
};

export default ImageComparer;
