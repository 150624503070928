import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { startUploading } from '../../features';
import { uploadFileThunk } from '../../thunks/fileUploadingThunk';
import { checkStateThunk } from '../../thunks/checkStateThunk';
import { getProcessedFileThunk } from '../../thunks/getProcessedFileThunk';
import Button from '../../ui/Button/Button';
import Dropzone from '../../ui/Dropzone/Dropzone';
import LoadingSpinner from '../../ui/LoadingSpinner/LoadingSpinner';
import ImageComparer from '../../ui/ImageComparer/ImageComparer';
import './Home.scss';

const Home: React.FC = () => {
  const dispatch = useDispatch();
  const fileUploadingState = useSelector((state: RootState) => state.filesUploading);
  const labelIdForOpenFileDialog = 'dropzone-button';
  const [ localImageUrl, setLocalImageUrl ] = useState<string | null>(null);
  const [ processedImageUrl, setProcessedImageUrl ] = useState<string | null>(null);

  const processImageFile = useCallback((fileBlob: Blob, setImageUrl: React.Dispatch<React.SetStateAction<string | null>>) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      setImageUrl(reader.result as string);
    };
    reader.readAsDataURL(fileBlob);
  }, []);

  const handleFileUpload = async (file: File) => {
    setProcessedImageUrl(null);
    processImageFile(file, setLocalImageUrl);

    await dispatch(startUploading({ fileName: file.name }));

    const resultAction = await dispatch<any>(uploadFileThunk(file));

    if (uploadFileThunk.fulfilled.match(resultAction)) {
      const id = resultAction.payload.client_id;
      const fileProcessingResult = await dispatch<any>(checkStateThunk(id));

      if (checkStateThunk.fulfilled.match(fileProcessingResult)) {
        const imageURL = fileProcessingResult.payload.file_link;
        const processedImageResult = await dispatch<any>(getProcessedFileThunk(imageURL));

        if (getProcessedFileThunk.fulfilled.match(processedImageResult)) {
          const processedImageBlob = new Blob([processedImageResult.payload], { type: 'image/jpeg' });

          processImageFile(processedImageBlob, setProcessedImageUrl);
        }
      }
    }
  };

  return <>
    <div className="demo">
      <div className="demo__inner">
        { fileUploadingState.status === 'idle' && (
          <div className="demo-col">
            <img src="assets/img/1stphoto-demo.jpg" alt="1stphoto, background remover" />
          </div>
        )}

        <div className="dropfile-col">
          <h1>Удаление фона изображения</h1>

          <Dropzone inputId={labelIdForOpenFileDialog} onFileSelect={handleFileUpload}>
            <label htmlFor={labelIdForOpenFileDialog}>
              <Button
                type="primary"
                size="l"
                disabled={ fileUploadingState?.status === 'loading' || fileUploadingState?.status === 'processing' || fileUploadingState?.status === 'processed' }
              >
                { fileUploadingState?.status && ['loading', 'processing', 'processed'].includes(fileUploadingState.status)
                  ? <LoadingSpinner size="l" type="light" />
                  : 'Загрузите изображение'
                }
              </Button>
              <p>
                { fileUploadingState?.status === 'idle' ? 'либо перетащите файл' : '' }
                { fileUploadingState?.status === 'loading' ? 'Загружется. Пожалуйста, подождите' : '' }
                { fileUploadingState?.status === 'processing' ? <><span className="success">Успешно загружено.</span> <span>Удаляем фон...</span></> : '' }
                { fileUploadingState?.status === 'processed' ? <><span className="success">Фон удалён.</span> <span>Скачиваем изображние...</span></> : '' }
                { fileUploadingState?.status === 'fileReceived' ? <><span className="success">Изображние скачено.</span> <span>Ещё одно?</span></> : '' }
                { fileUploadingState?.status === 'failed' ? <><span className="failure">Загрузка не удалась (( .</span> <span>Ещё раз попробуем?</span></> : '' }
              </p>
            </label>
          </Dropzone>
        </div>
      </div>
    </div>

    { (localImageUrl && fileUploadingState.status === 'fileReceived') && (
      <div className="comparer">
        <ImageComparer
          originalImageUrl={localImageUrl}
          processedImageUrl={processedImageUrl as string}
        />
      </div>
    )}
  </>;
};

export default Home;
