import { createAsyncThunk } from '@reduxjs/toolkit';
import { checkState } from '../api';
import { getIntFromEnv } from '../utils';

const requestAttempts = getIntFromEnv('REACT_APP_CHECK_REQUEST_ATTEMPTS', 7);
const requestAttemptTimeoutMs = getIntFromEnv('REACT_APP_DELAY_BETWEEN_CHECKS_MS', 1000);

const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export const checkStateThunk = createAsyncThunk(
  'fileFlow/checkFileStatus',
  async (id: string, { rejectWithValue }) => {
    let lastError;

    for (let attempt = 0; attempt < requestAttempts; attempt++) {
      try {
        const response = await checkState(id);

        if (response.ok) {
          const data = await response.json();

          if (typeof data.state === 'string' && (['pending', 'processing'].includes(data.state.toLowerCase()))) {
            throw new Error('The file is not processed yet');
          }

          return await data;
        }

        throw new Error('Check state responded with an error');
      } catch (error) {
        lastError = error;

        await delay(requestAttemptTimeoutMs);
      }
    }

    return rejectWithValue((lastError as Error)?.message ? (lastError as Error).message : 'Unknown error');
  }
);
