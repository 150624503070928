export type RejectedByTimeout = {
  ok: false;
  message: string;
}

export const createTimeout = (timeMs: number, processName = 'A process'): Promise<RejectedByTimeout> => new Promise((_, reject) =>
  setTimeout(() => reject({
    ok: false,
    message: `Timeout: ${processName} took too long`,
  }), timeMs),
);
