import { AnyAction, configureStore, Dispatch } from '@reduxjs/toolkit';
import { fileUploadingReducer, FileUploadState, legalDocumentsReducer, LegalDocumentsState } from './features';

export type RootState = {
  filesUploading: FileUploadState;
  legalDocuments: LegalDocumentsState;
}

export type StoreDependencies = {
  dispatch: Dispatch<AnyAction>;
};

export const store = configureStore({
  reducer: {
    filesUploading: fileUploadingReducer,
    legalDocuments: legalDocumentsReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    thunk: true,
    serializableCheck: false,
  }),
});
