import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactMarkdown from 'react-markdown';
import { RootState } from '../../store';
import { loadPrivacyPolicy } from '../../thunks/legalDocumentsThunks';
import './PrivacyPolicy.scss';

const PrivacyPolicy: React.FC = () => {
  const dispatch = useDispatch();
  const privacyPolicy = useSelector((state: RootState) => state.legalDocuments.privacyPolicy);

  useEffect(() => { // @ts-ignore
    dispatch(loadPrivacyPolicy());
  }, [dispatch]);

  const error = useSelector((state: RootState) => state.legalDocuments.error);

  return <div className="content">
    <div className="content__inner">
      {
        error
          ? <div>Что-то пошло не так(. Попробуйте позже.</div>
          : privacyPolicy
            ? <ReactMarkdown>{ privacyPolicy }</ReactMarkdown>
            : <div>Политика конфиденциальности загружается...</div>
      }
    </div>
  </div>;
}

export default PrivacyPolicy;
