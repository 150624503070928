import { Dispatch } from '@reduxjs/toolkit';
import { fetchUserAgreement, fetchPrivacyPolicy } from '../api';
import { requestLegalDocuments, setUserAgreement, setPrivacyPolicy, legalDocumentsError } from '../features';

export const loadUserAgreement = () => async (dispatch: Dispatch) => {
  dispatch(requestLegalDocuments());

  try {
    const response = await fetchUserAgreement();

    if (response.ok) {
      const content = await response.text();

      dispatch(setUserAgreement(content));
    } else {
      throw new Error('Failed to fetch user agreement');
    }
  } catch (error) {
    dispatch(legalDocumentsError());
  }
};

export const loadPrivacyPolicy = () => async (dispatch: Dispatch) => {
  dispatch(requestLegalDocuments());

  try {
    const response = await fetchPrivacyPolicy();

    if (response.ok) {
      const content = await response.text();

      dispatch(setPrivacyPolicy(content));
    } else {
      throw new Error('Failed to fetch privacy policy');
    }
  } catch (e) {
    dispatch(legalDocumentsError());
  }
};
