import { createSlice, Draft, PayloadAction, Slice } from '@reduxjs/toolkit';
import { uploadFileThunk } from '../thunks/fileUploadingThunk';
import { getProcessedFileThunk } from '../thunks/getProcessedFileThunk';
import { checkStateThunk } from '../thunks/checkStateThunk';

export type FileUploadState = {
  status: 'idle' | 'loading' | 'processing' | 'processed' | 'fileReceived' | 'failed';
  imageURL: string | undefined;
  fileName?: string;
  clientId?: string;
  errorMessage?: string;
};

const initialState: FileUploadState = {
  status: 'idle',
  imageURL: undefined,
};

export const fileUploadingSlice: Slice<FileUploadState> = createSlice({
  name: 'fileFlow',
  initialState,
  reducers: {
    startUploading(state: Draft<FileUploadState>, action: PayloadAction<{ fileName: string }>) {
      state.status = 'loading';
      state.fileName = action.payload.fileName;
      state.clientId = undefined;
      state.imageURL = undefined;
      state.errorMessage = undefined;
    },
    uploadFailure(state: Draft<FileUploadState>, action: PayloadAction<{ errorMessage: string }>) {
      state.status = 'failed';
      state.errorMessage = action.payload.errorMessage;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadFileThunk.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(uploadFileThunk.fulfilled, (state, action) => {
        state.status = 'processing';
        state.clientId = action.payload.client_id;
      })
      .addCase(uploadFileThunk.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.payload as string;
      })
      .addCase(checkStateThunk.fulfilled, (state, action) => {
        state.status = 'processed';
        state.imageURL = action.payload.file_link;
      })
      .addCase(checkStateThunk.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(getProcessedFileThunk.fulfilled, (state) => {
        state.status = 'fileReceived';
      })
      .addCase(getProcessedFileThunk.rejected, (state) => {
        state.status = 'failed';
      });
  }
});

export const { startUploading, uploadSuccess, uploadFailure } = fileUploadingSlice.actions;

export const fileUploadingReducer = fileUploadingSlice.reducer;
