import { createAsyncThunk } from '@reduxjs/toolkit';
import { getProcessedImage } from '../api';
import { getIntFromEnv, RejectedByTimeout } from '../utils';

const requestAttempts = getIntFromEnv('REACT_APP_DOWNLOADING_REQUEST_ATTEMPTS', 8);
const requestAttemptTimeoutMs = getIntFromEnv('REACT_APP_DOWNLOADING_DELAY_BETWEEN_REQUESTS_MS', 1000);

const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export const getProcessedFileThunk = createAsyncThunk(
  'fileFlow/getProcessedFile',
  async (imageURL: string, { rejectWithValue }) => {
    let lastError;

    for (let attempt = 0; attempt < requestAttempts; attempt++) {
      try {
        const response: Response | RejectedByTimeout = await getProcessedImage(imageURL);

        if (response.ok) {
          return await response.blob();
        }

        throw new Error('Downloading of the processed image failed');
      } catch (error) {
        lastError = error;

        await delay(requestAttemptTimeoutMs);
      }
    }

    return rejectWithValue((lastError as Error)?.message ? (lastError as Error).message : 'Unknown error');
  }
);
