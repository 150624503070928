import React from 'react';
import './Button.scss';

type ButtonProps = {
  id?: string;
  type?: 'primary' | 'secondary' | 'accent' | 'info' | 'light' | 'dark';
  disabled?: boolean;
  size?: 'xs' | 's' | 'm' | 'l' | 'xl';
  roundedCorners?: 'all' | 'top' | 'never';
  fullWidth?: boolean;
  onClick?: () => void;
  children?: React.ReactNode;
};

const Button: React.FC<ButtonProps> = ({
  id,
  type = 'secondary',
  disabled = false,
  size = 'm',
  roundedCorners = 'all',
  fullWidth = false,
  onClick,
  children = '',
}) => {
  const getButtonTypeClass = (type: ButtonProps['type']): string => {
    switch (type) {
      case 'primary':
        return 'fp-button_primary';
      case 'secondary':
        return 'fp-button_secondary';
      case 'accent':
        return 'fp-button_accent';
      case 'info':
        return 'fp-button_info';
      case 'light':
        return 'fp-button_light';
      case 'dark':
        return 'fp-button_dark';
      default:
        return 'fp-button_secondary';
    }
  };

  const getButtonSizeClass = (size: ButtonProps['size']): string => {
    switch (size) {
      case 'xs':
        return 'fp-button_xs';
      case 's':
        return 'fp-button_s';
      case 'm':
        return 'fp-button_m';
      case 'l':
        return 'fp-button_l';
      case 'xl':
        return 'fp-button_xl';
      default:
        return 'fp-button_m';
    }
  };

  const getRoundedCorners = (rounded: ButtonProps['roundedCorners']): string => {
    switch (rounded) {
      case ('all'):
        return 'fp-button_rounded-all';
      case ('top'):
        return 'fp-button_rounded-top';
      case ('never'):
        return 'fp-button_rounded-never';
      default:
        return 'fp-button_rounded-all';
    }
  }

  const buttonClasses = `fp-button ${getButtonTypeClass(type)} ${getButtonSizeClass(size)} ${getRoundedCorners(roundedCorners)} ${fullWidth ? 'fp-button_full-width' : ''}`;

  return (
    <button
      id={id}
      className={buttonClasses}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Button;
